hr {
    width: 50%;
    height: 2px;
    background-color: #002b36;
    border: none;
    margin: 50px auto 60px; }

.weapon_stats {
    margin-top: 30px;

    .header {
        margin-top: 23px;
        font-size: 24px;
        text-align: left; }

    .weapon_type {
        font-size: 14px;
        margin-bottom: 7px;
        font-weight: 600;
        color: #002b36cc;
        text-align: left; }

    .checkbox {
        text-align: left;
        width: 142px;
        cursor: pointer;

        .box {
            display: inline-block;
            vertical-align: middle;
            width: 23px;
            height: 23px;
            background-color: white;
            border: 2px solid #002b36;
            border-radius: 3px;

            .tick {
                font-size: 20px;
                line-height: 32px;
                padding-left: 1px;
                color: #d0b986;
                font-weight: 600; } }

        .label {
            display: inline-block;
            vertical-align: middle;
            padding-left: 10px;
            font-size: 18px; } }

    .requirements {
        width: 100%;
        overflow: auto;
        margin-top: 30px;

        table {
            width: 100%;
            // max-width: 500px
            margin: auto;
            border-collapse: collapse;
            font-weight: 600;

            thead {
                tr {
                    td {
                        padding: 5px;
                        background-color: #d0b986;
                        color: #002b36;

                        &:nth-child(1) {
                            background-color: transparent; } } } }

            tbody {
                tr {
                    td {
                        padding: 5px;
                        background-color: transparent;
                        color: #002b36;

                        &:nth-child(1) {
                            text-align: left;
                            font-size: 18px;
                            background-color: #d0b986;
                            color: #002b36; } } } } } }

    .column {
        width: 50%;
        display: inline-block;
        vertical-align: top;

        .damage {
            margin-top: 20px;

            table {
                border-collapse: collapse;
                margin: auto;

                tbody {
                    tr {
                        td {
                            padding: 5px;
                            font-size: 20px;
                            text-align: left;

                            .dmg {
                                width: 30px;
                                border: 1px solid #d0b986;
                                border-radius: 2px;
                                height: 30px;
                                line-height: 30px;
                                // margin: 3px 10px 0px
                                background-color: #d0b986;
                                color: #002b36;
                                font-weight: 600;
                                text-align: center;
                                outline-color: #002b36; }

                            .ar {
                                padding: 0px 5px;
                                font-size: 24px;
                                border: 1px solid #002b36;
                                border-radius: 2px;
                                padding-bottom: 3px;
                                line-height: 37px;
                                // margin: 3px 10px 0px
                                background-color: #002b36;
                                color: #d0b986;
                                font-weight: 600;
                                text-align: center;
                                outline-color: #d0b986;
                                text-decoration: underline;
                                text-decoration-style: double;
                                text-underline-offset: 3px;
                                font-weight: 600; }

                            &:nth-child(1) {
                                border-right: 2px solid #002b36;
                                padding: 5px 20px 5px 5px; }

                            &:nth-child(2) {
                                padding: 5px 5px 5px 20px; }

                            &:nth-child(3) {
                                border-right: 2px solid #002b36;
                                padding: 5px 20px 5px 5px; }

                            &:nth-child(4) {
                                padding: 5px 5px 5px 20px; } } } } } } } }

@media ( max-width: 730px ) {
    .weapon_stats {
        .column {
            width: 100%; } } }
