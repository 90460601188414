#menu.large {
    display: none; }

.navigation_container {
    display: inline-block;
    width: 250px;
    transition: 0.4s transform ease-in-out;

    .navigation {
        padding: 15px 0px;
        position: relative;
        height: calc(100vh - 30px);
        max-height: -moz-available;
        max-height: -webkit-fill-available;
        width: calc(250px);
        vertical-align: top;
        background-color: rgba(white, 0.8);
        border: none;
        transform: translate(0px, 0px);

        .x {
            display: none;
            position: absolute;
            top: 0px;
            right: 0px;
            font-size: 50px; }

        .header {
            margin: 15px 0px 30px;
            font-size: 24px;
            font-weight: 600; }

        .item {
            border-bottom: 2px solid #002b36;
            text-align: left;
            padding: 15px;
            justify-content: space-between;
            cursor: pointer;

            &:nth-child(3) {
                border-top: 2px solid #002b36; }

            .text {
                display: inline-block;
                vertical-align: middle;
                font-size: 20px; }

            .arrow {
                display: inline-block;
                vertical-align: middle;
                float: right;
                font-size: 24px; }

            &:hover {
                background-color: rgba(#002b36, 0.6); }

            &.active {
                background-color: #002b36;
                color: #d0b986; } } } }

@media (max-width: 990px) {
    .navigation_container {
        position: fixed;
        background-color: transparent;
        z-index: 4;
        transform: translate(calc(-100% - 1px), 0px);
        text-align: left;
        width: 100vw;

        .navigation {
            background-color: white;
            text-align: center;
            width: 300px;

            .x {
                display: block; }

            .header {
                margin: 30px 0px 30px; } } } }
