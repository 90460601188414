.optimised {
    display: inline-block;
    width: 680px;
    max-width: calc(100% - 30px);
    background-color: rgba(255, 255, 255, 0.8);
    padding: 15px;
    border-radius: 5px;
    text-align: center;
    margin-top: 15px;

    .title {
        font-family: Garamond;
        font-size: 26px;
        text-align: center;
        margin-bottom: 20px; }

    .sub-title {
        width: 300px;
        margin: 0px auto 20px; }

    .preresult {
        font-size: 18px; }

    .result {
        font-size: 24px;
        margin-bottom: 20px;

        .compare {
            text-align: center;
            margin-top: 50px;

            .text {
                display: block;
                margin-right: 10px;
                font-size: 20px; }

            .buttons {
                text-align: center;

                .compare_button {
                    font-size: 18px;
                    display: inline-block;
                    margin: 5px;
                    padding: 5px 10px;
                    cursor: pointer;
                    border-radius: 4px;
                    border: 1px solid #002b36;

                    &.active {
                        background-color: #002b36;
                        color: #d0b986; } } }

            select {
                background-color: #002b36;
                color: #d0b986;
                font-size: 20px;
                font-family: Garamond;
                outline: none;
                border: none;
                padding: 5px 10px; } }

        .stats {
            font-size: 20px;
            text-align: left;
            margin-top: 20px;

            .column {
                display: inline-block;
                vertical-align: top;
                width: 50%;

                .stat {
                    font-size: 16px;

                    .label {
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: 10px;
                        width: 114px; }

                    .value {
                        display: inline-block;
                        vertical-align: middle;
                        font-weight: 600;
                        height: 23px;
                        line-height: 23px;
                        background-color: #002b36;
                        padding: 5px 10px;
                        border-radius: 4px;
                        margin: 5px 0px;
                        color: #d0b986; }

                    .comparison {
                        display: inline-block;
                        vertical-align: middle;
                        margin-left: 10px;
                        font-weight: 600; } } } } }

    .table_container {
        width: 100%;
        overflow-x: auto;

        table {
            width: 100%;

            thead {
                tr {
                    td {} } }

            tbody {
                tr {
                    td {} } } } }

    .levels {
        .level {
            margin-bottom: 10px;

            .title {
                font-size: 18px;
                text-align: left;
                display: inline-block;
                width: 90px;
                vertical-align: initial; }

            .level_value {
                display: inline-block;
                vertical-align: middle;
                width: 30px;
                border: 1px solid #002b36;
                border-radius: 2px;
                height: 30px;
                line-height: 30px;
                margin: 3px 10px 0px;
                background-color: #002b36;
                color: #d0b986;
                font-weight: 600;
                text-align: center;
                outline: none; }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0; }

            input[type=number] {
                -moz-appearance: textfield; }

            .slider_container {
                display: inline-block;
                vertical-align: top;
                width: calc(100% - 204px);

                .slider {
                    width: 100%;
                    margin: 5px 0px;
                    -webkit-appearance: none;
                    appearance: none;
                    background: #d3d3d3;
                    height: 12px;
                    overflow: hidden;
                    border-radius: 6px;
                    outline: none;

                    &::-webkit-slider-thumb {
                        -webkit-appearance: none;
                        appearance: none;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        background: #002b36;
                        cursor: pointer;
                        box-shadow: -2005px 0 0 2000px #d0b986; }

                    &::-moz-range-thumb {
                        width: 25px;
                        height: 25px;
                        background: #d0b986;
                        cursor: pointer; }

                    &::-webkit-slider-runnable-track {
                        background: white; }

                    &::-ms-fill-lower, &::-moz-range-progress {
                        background: #d0b986; } } } } } }

@media (max-width: 500px) {
        .levels {
            .level {
                text-align: left;

                .title {
                    display: block!important;
                    text-align: left!important;
                    margin: 0px!important;
                    margin-top: 20px!important; }

                .level_value {
                    margin-left: 0px!important; }

                .slider_container {
                    width: calc(100% - 111px)!important; } } } }

@media (max-width: 750px) {
    .optimised {
        .result {
            .stats {
                .column {
                    display: block!important;
                    width: 100%!important; } } } } }
