.footer {
    margin-top: 15px;
    margin-bottom: -15px;
    display: inline-block;
    width: 680px;
    max-width: calc(100% - 30px);
    background-color: rgba(255, 255, 255, 0);
    padding: 15px;
    border-radius: 10px;
    text-align: center;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    z-index: 1;

    .credit {
        a {
            color: #002b36;
            text-underline-offset: 3px;
            font-weight: 600; }

        hr {
            border: none;
            height: 2px;
            background-color: #002b36;
            width: 200px;
            margin: 20px auto; } } }
