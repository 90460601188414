.runes {
    display: inline-block;
    width: 680px;
    max-width: calc(100% - 30px);
    background-color: rgba(255, 255, 255, 0.8);
    padding: 15px;
    border-radius: 5px;
    text-align: center;
    margin-top: 15px;

    .title {
        font-family: Garamond;
        font-size: 26px;
        text-align: center;
        margin-bottom: 20px; }

    .input_box {
        position: relative;
        display: inline-block;
        width: 300px;
        vertical-align: middle;

        .input {
            text-align: center;
            display: inline-block;
            width: 50%;

            .input_title {
                font-size: 18px;
                margin-bottom: 10px;
                font-family: Garamond; }

            input {
                border: none;
                border-bottom: 5px solid #d0b986;
                border-radius: 5px;
                padding: 15px;
                width: 50px;
                text-align: center;
                font-size: 20px;
                font-family: Garamond;
                font-weight: bold;
                outline: none; }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0; }

            input[type=number] {
                -moz-appearance: textfield; } }

        .arrow {
            font-weight: 900;
            font-size: 30px;
            position: absolute;
            top: 35%;
            left: 50%;
            transform: translate(-50%, 0%); } }

    .required {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 44px;
        position: relative;
        margin-left: 30px;

        .equals {
            font-weight: 900;
            font-size: 30px;
            position: absolute;
            top: 60%;
            left: -15px;
            transform: translate(-50%, 0%); }

        .title {
            font-size: 18px;
            text-align: center;
            margin-bottom: 15px; }

        .value {
            text-align: center;
            font-size: 40px;
            margin-bottom: -10px; } } }
