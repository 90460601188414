@keyframes spin {
    0% {
        transform: rotate(0deg); }

    100% {
        transform: rotate(360deg); } }

.weapons {
    display: inline-block;
    width: 680px;
    max-width: calc(100% - 30px);
    background-color: rgba(255, 255, 255, 0.8);
    padding: 15px;
    border-radius: 10px;
    text-align: center;
    margin-top: 15px;
    position: relative;

    .add_weapon_button {
        font-size: 20px;
        background-color: #002b36;
        color: #d0b986;
        border: none;
        width: 250px;
        padding: 10px 20px;
        cursor: pointer;
        font-family: Garamond;
        border-radius: 4px;
        margin: 20px auto 0px; }

    &.large {
        width: 1130px; }

    #input.large, #result.large {
        display: inline-block;
        vertical-align: top; }

    #input.large {
        margin-right: 80px;
        margin-top: 20px;
        position: sticky;
        top: 0; }

    .fullscreen {
        font-size: 20px;
        position: absolute;
        top: 10px;
        right: 10px; }

    .loader {
        margin: -15px;
        width: 100%;
        max-width: calc(100% - 30px);
        height: calc(100% - 30px);
        background-color: rgba(white, 0.2);
        position: absolute;
        padding: 15px;
        border-radius: 10px;
        backdrop-filter: blur(5px);
        z-index: 10;

        .spinner {
            width: 50px;
            height: 50px;
            border: 5px solid black;
            border-bottom: 5px solid transparent;
            border-radius: 50%;
            position: absolute;
            top: calc(50% - 30px);
            left: calc(50% - 30px);
            animation: spin 0.6s infinite linear; } }

    .title {
        font-family: Garamond;
        font-size: 26px;
        text-align: center;
        margin-bottom: 20px; }

    .sub-title {
        width: 300px;
        margin: 0px auto 20px; }

    .optimal_weapon {
        margin-top: 30px;

        button {
            font-size: 20px;
            background-color: #002b36;
            color: #d0b986;
            border: none;
            width: 100%;
            padding: 10px 20px;
            cursor: pointer;
            font-family: Garamond;
            border-radius: 4px; } }

    .or {
        margin-top: 30px;
        margin-bottom: 10px;
        font-size: 20px;
        color: #002b36;

        .arrow {
            font-size: 26px;
            vertical-align: middle; } }

    .weapon_input {
        text-align: left;

        .x {
            text-align: right;
            font-size: 40px;
            margin-bottom: -30px;
            cursor: pointer; }

        .weapon_details {
            text-align: left;
            margin-top: 20px;
            display: inline-block;
            vertical-align: bottom;
            padding-right: 15px;
            max-width: 100%;
            color: #002b36;

            .search_icon {
                display: inline-block;
                font-size: 20px;
                font-weight: 900;
                // background-color: #002b36
                border: 3px solid #002b36;
                padding: 10px;
                height: 17px;
                width: 17px;
                // color: #d0b986
                color: #002b36;
                border-radius: 50%;
                border-bottom-right-radius: 0px;
                // border-top-right-radius: 0px
                cursor: pointer;
                vertical-align: bottom; }

            .label {
                font-size: 16px;
                margin-bottom: 5px; }

            .datalist, .select {
                font-size: 18px;
                padding: 5px 0px;
                width: 300px;
                cursor: pointer;
                outline: none;
                border: none;
                border-bottom: 3px solid #002b36;
                background-color: transparent;
                font-family: Garamond;
                color: #002b36; }

            .datalist {
                max-width: calc(100% - 43px);
                margin-bottom: 0px;
                vertical-align: bottom; }

            .select {
                width: 100px;
                text-indent: 0px; }

            #input_weapon {
                width: calc(100% - 103px); }

            input::-webkit-calendar-picker-indicator {
                opacity: 100; } } }

    .levels {
        .level {
            margin-bottom: 10px;

            .title {
                font-size: 18px;
                text-align: left;
                display: inline-block;
                width: 90px;
                vertical-align: initial; }

            .level_value {
                display: inline-block;
                vertical-align: middle;
                width: 30px;
                border: 1px solid #002b36;
                border-radius: 2px;
                height: 30px;
                line-height: 30px;
                margin: 3px 10px 0px;
                background-color: #002b36;
                color: #d0b986;
                font-weight: 600;
                text-align: center;
                outline: none; }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0; }

            input[type=number] {
                -moz-appearance: textfield; }

            .slider_container {
                display: inline-block;
                vertical-align: top;
                width: calc(100% - 211px);

                .slider {
                    width: 100%;
                    margin: 5px 0px;
                    -webkit-appearance: none;
                    appearance: none;
                    background: #d3d3d3;
                    height: 12px;
                    overflow: hidden;
                    border-radius: 6px;
                    outline: none;

                    &::-webkit-slider-thumb {
                        -webkit-appearance: none;
                        appearance: none;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        background: #002b36;
                        cursor: pointer;
                        box-shadow: -2005px 0 0 2000px #d0b986; }

                    &::-moz-range-thumb {
                        width: 25px;
                        height: 25px;
                        background: #d0b986;
                        cursor: pointer; }

                    &::-webkit-slider-runnable-track {
                        background: white; }

                    &::-ms-fill-lower, &::-moz-range-progress {
                        background: #d0b986; } } } } } }
