.heading {
    z-index: 1;

    .burger {
        display: none; }

    .logo_box {
        max-width: calc(100%);
        width: 650px;
        margin: auto;
        position: relative;

        .logo {
            display: block;
            margin: auto;
            width: 100%;

            .sub {
                width: 100%;
                text-align: right;
                margin: auto;
                padding-right: 57px;
                color: #d0b986;
                font-size: 30px;
                font-weight: 600;
                position: absolute;
                right: 20px;
                bottom: 0px; } } } }

@media (max-width: 990px) {
    .heading {
        .burger {
            display: inline-block;
            color: white;
            font-size: 50px;
            font-weight: 800;
            vertical-align: middle;
            margin-bottom: -16px;
            margin-right: 15px; }

        .logo_box {
            display: inline-block;
            width: calc(100% - 65px);
            vertical-align: middle; } } }
