.bottom {
    width: 500px;
    max-width: calc(100% - 50px);
    margin: auto;
    margin-top: 20px;
    display: none;

    .text {
        font-size: 18px;
        color: white;

        .button {
            color: #d0b986;
            display: inline-block;
            cursor: pointer;
            font-weight: 600; } } }

@media (max-width: 990px) {
    .bottom {
        display: block; } }
