.weapons {
    .optimal {
        text-align: left;

        .back {
            font-weight: 600;
            cursor: pointer;

            .arrow {
                display: inline-block;
                vertical-align: middle;
                font-size: 26px; }

            .text {
                display: inline-block;
                vertical-align: text-bottom;
                font-size: 18px; } }

        .header {
            font-family: Garamond;
            font-size: 26px;
            text-align: center;
            margin-bottom: 20px; }

        .filters {
            margin-bottom: 60px;
            margin-top: 30px;

            .title {
                text-align: left;
                font-size: 22px;
                margin-bottom: 0px; }

            .filter {
                display: inline-block;
                vertical-align: middle;
                margin-right: 10px;
                margin-top: 20px;

                .label {
                    font-size: 16px; }

                select {
                    font-size: 18px;
                    padding: 5px 0px;
                    // width: 300px
                    cursor: pointer;
                    outline: none;
                    border: none;
                    border-bottom: 3px solid #002b36;
                    background-color: transparent;
                    font-family: Garamond;
                    color: #002b36; } } }

        .results {
            .noresult {
                text-align: center;
                font-size: 20px;
                font-weight: 600; }

            .loadmore {
                text-align: center;
                font-size: 18px;
                background-color: #002b36;
                color: #d0b986;
                width: 100px;
                padding: 10px 20px;
                font-weight: 600;
                border-radius: 4px;
                margin: 20px auto 0px;
                cursor: pointer; }

            hr {
                width: 50%;
                height: 2px;
                background-color: #002b36;
                border: none;
                margin: 50px auto 60px; }

            .optimal_weapon_item {

                .overview {
                    display: inline-block;
                    vertical-align: top;
                    width: 100%;

                    .name {
                        font-size: 24px;
                        font-weight: 600;
                        color: #002b36; }

                    .weapon_type {
                        font-size: 14px;
                        margin-top: 7px;
                        font-weight: 600;
                        color: #002b36cc; }

                    .attack_rating {
                        font-size: 22px;
                        font-weight: 600;
                        color: #002b36;

                        .value {
                            display: inline-block;
                            vertical-align: middle;
                            margin-left: 15px;
                            font-size: 24px;
                            border: 1px solid #002b36;
                            border-radius: 2px;
                            padding: 0px 5px;
                            padding-bottom: 3px;
                            line-height: 37px;
                            background-color: #002b36;
                            color: #d0b986;
                            font-weight: 600;
                            text-align: center;
                            outline-color: #d0b986;
                            text-decoration: underline;
                            text-decoration-style: double;
                            text-underline-offset: 3px;
                            font-weight: 600; } }

                    .requirements {
                        margin-top: 20px;
                        overflow: auto;
                        width: 100%;

                        table {
                            width: 100%;
                            margin: auto;
                            border-collapse: collapse;
                            font-weight: 600;

                            thead {
                                tr {
                                    td {
                                        padding: 5px;
                                        background-color: #d0b986;
                                        color: #002b36;

                                        &:nth-child(1) {
                                            background-color: transparent; } } } }

                            tbody {

                                tr {
                                    td {
                                        padding: 5px;
                                        background-color: transparent;
                                        color: #002b36;
                                        text-align: center;

                                        &:nth-child(1) {
                                            text-align: left;
                                            font-size: 18px;
                                            background-color: #d0b986;
                                            color: #002b36; } } } } } } }

                .column {
                    width: 50%;
                    display: inline-block;
                    vertical-align: top;
                    text-align: center;

                    .damage {
                        display: inline-block;
                        vertical-align: top;
                        // margin-top: 20px
                        text-align: left;
                        // margin-top: 20px

                        table {
                            border-collapse: collapse;
                            margin: auto;

                            tbody {
                                tr {
                                    td {
                                        padding: 5px;
                                        font-size: 20px;
                                        text-align: left;

                                        .dmg {
                                            width: 30px;
                                            border: 1px solid #d0b986;
                                            border-radius: 2px;
                                            height: 30px;
                                            line-height: 30px;
                                            // margin: 3px 10px 0px
                                            background-color: #d0b986;
                                            color: #002b36;
                                            font-weight: 600;
                                            text-align: center;
                                            outline-color: #002b36; }

                                        .ar {
                                            font-size: 24px;
                                            border: 1px solid #002b36;
                                            border-radius: 2px;
                                            padding: 0px 5px;
                                            padding-bottom: 3px;
                                            line-height: 37px;
                                            // margin: 3px 10px 0px
                                            background-color: #002b36;
                                            color: #d0b986;
                                            font-weight: 600;
                                            text-align: center;
                                            outline-color: #d0b986;
                                            text-decoration: underline;
                                            text-decoration-style: double;
                                            text-underline-offset: 3px;
                                            font-weight: 600; }

                                        &:nth-child(1) {
                                            border-right: 2px solid #002b36;
                                            padding: 5px 20px 5px 5px; }

                                        &:nth-child(2) {
                                            padding: 5px 5px 5px 20px; }

                                        &:nth-child(3) {
                                            border-right: 2px solid #002b36;
                                            padding: 5px 20px 5px 5px; }

                                        &:nth-child(4) {
                                            padding: 5px 5px 5px 20px; } } } } } } } } } } }

@media ( max-width: 730px ) {
    .weapons {
        .optimal {
            .results {
                .optimal_weapon_item {
                    .overview, .column {
                        width: 100%; } } } } } }
