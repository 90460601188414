.consumables {
    margin-top: 15px;
    display: inline-block;
    width: 680px;
    max-width: calc(100% - 30px);
    background-color: rgba(255, 255, 255, 0.8);
    padding: 15px;
    border-radius: 5px;
    text-align: center;

    .title {
        font-family: Garamond;
        font-size: 26px;
        text-align: center;
        margin-bottom: 20px; }

    .subtitle {
        font-family: Garamond;
        font-size: 22px;
        text-align: center;
        margin-bottom: 20px;
        text-decoration: underline;
        text-underline-offset: 4px; }

    .consumable {
        margin-bottom: 10px;

        .name {
            font-size: 20px;
            display: inline-block;
            vertical-align: middle;
            width: 200px;
            text-align: right;
            padding-right: 20px; }

        .number_input {
            display: inline-block;
            vertical-align: middle;

            .minus {
                // border: 1px solid black
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                background-color: #d0b986;
                border-bottom: 5px solid #d0b986;
                display: inline-block;
                vertical-align: middle;
                width: 30px;
                height: 30px;
                margin-right: -1px;
                cursor: pointer;
                font-size: 30px;
                line-height: 25px;
                font-weight: 600; }

            input {
                border: none;
                border-bottom: 5px solid #d0b986;
                display: inline-block;
                vertical-align: middle;
                font-size: 20px;
                width: 100px;
                height: 28px;
                outline: none;
                text-align: center; }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0; }

            input[type=number] {
                -moz-appearance: textfield; }

            .plus {
                // border: 1px solid black
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                background-color: #d0b986;
                border-bottom: 5px solid #d0b986;
                display: inline-block;
                vertical-align: middle;
                width: 30px;
                height: 30px;
                margin-left: -1px;
                cursor: pointer;
                font-size: 24px;
                line-height: 30px;
                font-weight: 900; } } }

    .result {
        font-size: 40px; } }
