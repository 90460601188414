@font-face {
    font-family: 'Garamond';
    src: url("./assets/fonts/Garamond Premier Pro Regular.ttf") format("truetype"); }

body {
    margin: 0px;
    background-color: #002b36;
    overflow: hidden;
    overflow-anchor: none;
    font-family: Garamond;

    .App {
        overflow-x: hidden;
        height: 100vh;
        max-height: -webkit-fill-available;
        width: 100vw;
        position: absolute;
        overflow-y: hidden;
        overflow-x: hidden;
        text-align: center;

        .video_background {
            height: 100%;
            position: fixed;
            bottom: 0px;
            transform: translate(-50%, 0px);
            left: 50%;
            overflow-x: hidden;
            z-index: -1;
            opacity: 0.4; }

        @import "./components/navigation/navigation";

        .body {
            display: inline-block;
            vertical-align: top;
            width: calc(100% - 280px);
            overflow-y: auto;
            padding: 15px;
            height: calc(100vh - 30px);
            max-height: -webkit-fill-available;
            scroll-behavior: smooth;

            // #rune-calculator, #build-calculator, #weapon-calculator, #consumables-calculator
            //     display: none

            &.large {
                display: block;
                width: calc(100% - 30px); }

            @import "./components/footer/heading";
            @import "./components/builds/optimised";
            @import "./components/weapon/weapons";
            @import "./components/weapon/weapon";
            @import "./components/footer/bottom";
            @import "./components/weapon/optimal";

            .runes_container {
                z-index: 3;

                @import "./components/levels/runes";
                @import "./components/levels/consumables";

                .credit {
                    position: absolute;
                    bottom: 10px;
                    right: 10px;
                    color: white;
                    font-family: sans-serif;

                    a {
                        color: #d0b986;
                        text-underline-offset: 4px; } } } } } }

@media (max-width: 990px) {
    body {
        .App {
            .body {
                width: calc(100% - 30px); } } } }

@media (max-width: 920px) {
    body {
        .App {
            .body {
                .runes_container {
                    .runes {
                        .input-box {
                            display: block; }

                        .required {
                            width: 100%;
                            margin-left: 0px!important;
                            margin-top: 15px;

                            .equals {
                                display: none; } } } } } } } }

@media (max-width: 460px) {
    body {
        .App {
            .body {
                .runes_container {
                    .consumables {
                        .consumable {
                            .name {
                                text-align: center;
                                margin: 10px auto 5px;
                                padding-right: 0px;
                                display: block; } } } } } } } }
